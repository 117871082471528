<script>
import ApplicationAdd from "@/components/application/application-add";

import Repository from "../../../app/repository/repository-factory";
import PageHeader from "@/components/page-header.vue";

const ApplicationReportRepository = Repository.get("ApplicationReportRepository");
const ApplicationBeneficiaryRepository = Repository.get("ApplicationBeneficiaryRepository");

export default {
  components: {PageHeader, ApplicationAdd},
  props: {
    beneficiaryId: {
      type: String,
      default: '',
      required: false
    },
  },
  data() {
    return {
      form: {
        subaccount: null,
        date: null
      },
      subaccountList: [],
      isBusy: false,
      excelInProgress: false,
      title: "Wnioski",
      totalRows: 1,
      items: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: "",
      filterOn: [''],
      sortBy: 'createdAt',
      sortDesc: true,
      statusName: '',
      fields: [
        {key: "applicationNumber", label: "Numer wniosku", sortable: true},
        {key: "beneficiary", label: "Beneficjent", sortable: true},
        {key: "applicationDate", label: "Data wniosku", sortable: true},
        {key: "lastApplicationStatusName", label: "Status wniosku"},
        {key: "withdrawals", label: "Wypłacono"},
        {key: "applicationCreatedBy", label: "Utworzył(a)"},
        {key: 'action', label: "Akcje"}
      ],
      statuses: [
        {value: '', text: 'Wszystkie statusy'},
        {value: 'in_preparation', text: 'W trakcie przygotowania'},
        {value: 'for_approval', text: 'Przekazany do zatwierdzenia'},
        {value: 'correction', text: 'Korekta'},
        {value: 'incorrect', text: 'Niepoprawny'},
        {value: 'awaiting_settlement', text: 'Oczekuje na rozliczenie'},
        {value: 'partially_settled', text: 'Częściowo rozliczony'},
        {value: 'settled', text: 'Rozliczony'},
      ]
    };
  },
  created() {
    this.getBeneficiaryList()
  },
  watch: {
    filter: function () {
      this.currentPage = 1;
    },
    statusName: function () {
      this.currentPage = 1;
      this.$refs.searchTable.refresh()
    }
  },
  methods: {
    getBeneficiaryList() {
      const promise = ApplicationBeneficiaryRepository.getAllQuickSearch();

      return promise.then((data) => {
        this.subaccountList = data.data;

      }).catch(error => {
        console.log(error);
      })
    },
    axiosProvider(ctx) {
      const parameters = '?page=' + ctx.currentPage + '&maxItems=' + ctx.perPage + '&search=' + this.filter +
        '&orderBy=' + this.sortBy + '&orderDesc=' + this.sortDesc + '&statusName=' + this.statusName + '&beneficiaryId=' + this.beneficiaryId;
      const promise = ApplicationReportRepository.getReport(parameters);

      return promise.then((data) => {
        this.totalRows = data.data.info.allItems;
        return data.data.data || []
      }).catch(error => {
        console.log(error);
        return []
      })
    },
    changeStatus(keyStatus) {
      let item = this.statuses.find(element => element.value === keyStatus);
      return item.text;
    },
    amountToSettled(amountToSettled) {
      let amountToSettledOut = 0;

      if (amountToSettled !== null) {
        amountToSettledOut = parseFloat(amountToSettled);
      }

      return amountToSettledOut;
    }
  }
};
</script>

<template>
  <div>
  <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="col-sm-12 col-lg-12">
          <application-add :beneficiary-id="this.beneficiaryId"></application-add>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12 col-lg-12">
                <b-form inline>
                  <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                  <div class="ml-2 search-box">
                    <div class="position-relative">
                      <b-form-input v-model="filter" :debounce="350" placeholder="Szukaj..."></b-form-input>
                      <i class="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                  <b-form-select class="ml-1" v-model="statusName" :options="statuses"></b-form-select>
                </b-form>
              </div>
            </div>
            <!-- End search -->
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="searchTable"
                :items="axiosProvider"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn">

                <template v-slot:table-busy>
                  <div class="text-center text-black">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Ładowanie danych...</strong>
                  </div>
                </template>

                <template v-slot:cell(lastApplicationStatusName)="row">
                  {{
                    changeStatus((row.item.lastSettlementStatus !== null) ? row.item.lastSettlementStatus : row.item.lastApplicationStatusName)
                  }}
                </template>

                <template v-slot:cell(withdrawals)="row">


                   <span v-if="row.item.settlementId !== null" class="badge badge-pill font-size-12" :class=" {
                     'badge-warning': row.item.lastSettlementStatus === 'awaiting_settlement' || row.item.lastSettlementStatus === 'partially_settled',
                     'badge-success': row.item.lastSettlementStatus === 'settled'
                   }">

                   {{ row.item.totalWithdrawal.toFixed(2) }} z {{
                       amountToSettled(row.item.amountToSettled).toFixed(2)
                     }} zł
                   </span>

                </template>

                <template v-slot:cell(applicationCreatedBy)="row">
                  {{ row.item.applicationCreatedBy }}
                  <span v-if="row.item.comment"><i class="bx bx bx-info-circle" id="tooltip-comment"></i>
                  <b-tooltip target="tooltip-comment" triggers="hover">
                    {{ row.item.comment }}
                  </b-tooltip></span>
                </template>

                <template v-slot:cell(action)="row">
                  <ul class="list-inline font-size-20 contact-links mb-0">
                    <li class="list-inline-item px-2">
                      <a v-b-tooltip.hover title="Edycja / podgląd wniosku">
                        <b-link :to="{ name: 'Edycja / podgląd wniosków', params: { id: row.item.applicationId } }">
                          <i class="bx bx-edit"></i></b-link>
                      </a>

                    </li>
                  </ul>
                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <template v-if="totalRows === 0">
                  <div class="d-flex justify-content-center mt-3">
                    <b>Nie znaleziono elementów</b>
                  </div>
                </template>
                <template v-if="totalRows > 0">
                  <div class="dataTables_paginate paging_simple_numbers float-left ml-2">
                    Znaleziono {{ totalRows }} rekordów.
                  </div>
                </template>
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalRows"
                                  :per-page="perPage"
                                  first-number last-number></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
