<script>
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pl';

import Repository from "../../app/repository/repository-factory";
import {required} from "vuelidate/lib/validators";
import Vue from "vue";

const SubaccountRepository = Repository.get("SubaccountRepository");
const ApplicationApplicationRepository = Repository.get("ApplicationApplicationRepository");

export default {
  components: {Multiselect, DatePicker},
  props: {
    beneficiaryId: {
      type: String,
      default: '',
      required: false
    },
  },
  data() {
    return {
      subaccountList: [],
      isBusy: false,
      submitted: false,
      form: {
        subaccount: '',
        date: ''
      },
    };
  },
  validations: {
    form: {
      subaccount: {required},
      date: {required},
    }
  },
  created() {
    this.getSubaccountList()
  },
  methods: {
    createApplication() {
      this.formSubmit();

      if (this.$v.form.$error === true) {
        return false;
      }

      let payload = {'beneficiaryId': this.form.subaccount.uuid, 'date': this.form.date}

      ApplicationApplicationRepository.create(payload).then((response) => {
        Vue.swal({
          icon: "success",
          toast: false,
          position: 'top',
          title: "SUKCES! ",
          text: 'Wniosek został dodany!',
          showConfirmButton: false,
          timer: 500,
          onClose: () => {
            this.$router.push({ name: "Edycja / podgląd wniosków", params: { id: response.data.id }})
          }
        });
        this.inProgress = false;
      }).catch(error => {
        Vue.swal({
          icon: "error",
          position: 'top',
          title: "Wystąpił błąd. Spróbuj jeszcze raz!",
          text: error.response.data.message,
          showConfirmButton: true
        });
        this.inProgress = false;
      })
    },
    getSubaccountList() {
      const promise = SubaccountRepository.getQuickSearch();

      return promise.then((data) => {
        this.subaccountList = data.data;

        if (this.beneficiaryId !== '') {
          this.autoSetBeneficiary(this.beneficiaryId)
        }

      }).catch(error => {
        console.log(error);
      })
    },
    autoSetBeneficiary(beneficiaryId) {
      let beneficiary = this.subaccountList.find(element => element.uuid === beneficiaryId);
      this.form.subaccount = beneficiary;
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.form.$touch()
    }
  }
};
</script>
<template>
  <form class="needs-validation" @submit.prevent="formSubmit">
    <div class="row">
      <div class="col-md-4 col-lg-4">
        <div class="form-group">
          <date-picker
            id="date"
            v-model="form.date"
            :first-day-of-week="1"
            value-type="format"
            :class="{ 'is-invalid': submitted && $v.form.date.$error }"
            lang="pl">
          </date-picker>
          <div v-if="submitted && $v.form.date.$error" class="invalid-feedback">
            <span v-if="!$v.form.date.required">Ta wartość jest wymagana.<br></span>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-lg-5">
        <div class="form-group">
          <multiselect
            id="date"
            :multiple="false"
            v-model="form.subaccount"
            :options="subaccountList"
            :optionsLimit=1000
            :preserve-search="true"
            track-by="uuid"
            label="fullName"
            placeholder="Wybierz"
            selectLabel=""
            deselectLabel="Odznacz"
            :class="{ 'is-invalid': submitted && $v.form.subaccount.$error }"
            selectedLabel="Wybrane">
            <template v-slot:noResult>
              Brak rezultatów.
            </template>
          </multiselect>
          <div v-if="submitted && $v.form.subaccount.$error" class="invalid-feedback">
            <span v-if="!$v.form.subaccount.required">Ta wartość jest wymagana.<br></span>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-lg-3">
        <div class="float-sm-right">
          <button type="button" v-on:click="createApplication"
                  class="btn btn-primary waves-effect waves-light">
            <i class="bx bxs-add-to-queue mr-1"></i> Utwórz wniosek
            <template v-if="isBusy">
              <b-spinner small class="ml-2" variant="light" role="status"></b-spinner>
            </template>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>
