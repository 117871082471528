<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import ApplicationReportGrid from "./application-report-grid"

export default {
  components: {Layout, PageHeader, ApplicationReportGrid}
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <application-report-grid></application-report-grid>
  </Layout>
</template>
